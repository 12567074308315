/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
@import "sass-lib";

body {  
    color: #333;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    overflow-x: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
    &:focus {
        -moz-appearance: number-input;
    }
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }
/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper {width: 1430px;margin: 0 auto;}

/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}
.a-left{text-align: left}
.a-right{text-align: right}
.a-center{text-align: center}
.italic { font-style: italic; }
.bold { font-weight: bold; }
.regular { font-weight: lighter; }
.fl { float: left; }
.fr { float: right; }
.clear { clear: both; }

.title {
    font-size: 16px; text-transform: uppercase; color: $c_666; padding: 24px 0 0 30px; line-height: 14px; font-weight: 700;
    background: url('../images/material/bg_title_2.png') no-repeat left center; display: inline-block; margin-bottom: 40px;
    &.white {
        color: #fff;background: url('../images/material/bg_title.png') no-repeat left center;
    }
}
.btn {
    display: inline-block; padding: 0 15px 0 15px; background: $c_ylow ; color: #fff; line-height: 40px; position: relative;
    font-size: 13px; font-weight: 700; text-transform: uppercase; @include transition(background ease-out 0.25s); margin-top: 30px;
    /*    i { 
            width: 7px; height: 11px; background: url('../images/material/arr_btn.png') no-repeat; display: inline-block; position: absolute;
            right: 20px; top: 0; bottom: 0; margin: auto; @include transition(all ease-out 0.25s);
        }*/
    i{display: none;}
    &.back{
        margin-left: 90px;
        &:after{ left:-43px; top: 0; @include transform(rotate(180deg)); }
        &:hover{
            &:after{ left:-50px; }
        }
    }
    &:hover {
        @include transition(background ease-out 0.25s); background: #e79b07; color: #FFF;
        /*        i { @include transition(all ease-out 0.25s); right: 15px;}*/
        &:after{right:-50px;}
    }
    &:after{
        content:"";width:40px;height:40px;background: url(../images/material/arr-btn.png) no-repeat;right:-42px;top:0;position: absolute;
        @include transition(all ease-out 0.15s); 
    }
}
.learn {
    font-size: 16px; color: #fff; text-transform: uppercase; font-weight: 700; display: inline-block;@include transition(background-position linear 0.25s);
    background: url('../images/material/arr_btn.png') no-repeat 90% center; padding: 10px 35px 10px 0;
    &:hover {
        @include transition(background-position linear 0.25s); background-position: 95% center; color: #fff;
    }
}


.loaderhome {
    position: fixed;top: 0;left: 0;right: 0;bottom: 0;
    background: #fff; z-index: 10000; overflow: hidden;
    .logobg {
        position: absolute; margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(../images/material/loader.gif) no-repeat center;
        background-size: 1200px;
        opacity: 1;
    }
}

/* header
----------------------------------------------------------------------------------------------*/
header {
    position: absolute; left: 0; right: 0; top: 0; z-index: 9; padding-top: 70px;
    .logo {
        float: left;position: relative;
        img {
            &.stay {display: none;}
        }
    }
    .rh_head {
        float: right; @extend .after_clear; position: relative;
        .main_nav {
            float: left; @extend .after_clear; margin-top: 18px; margin-right: 20px;
            ul {
                li {
                    float: left; margin-right: 20px; position: relative;
                    a {font-size: 16px;  font-weight: 700; color: #fff; padding: 15px; display: inline-block; text-transform: uppercase;}
                    &:hover{
                        .drop{opacity: 1;visibility: visible;}
                    }
                }
            }
            a.have-drop{
                position: relative;
                &:after{
                    content:"";width:0;height:0;position:absolute;border-top:5px solid #FFF;border-left:5px solid transparent;
                    border-right:5px solid transparent;right: -2px;margin:auto;top:0;bottom:0;
                }
            }
            .drop{
                position: absolute;background:#FFF;@include boxShadow(0px 0px 15px -5px);top:56px;left:-40px;opacity:0;visibility:hidden;
                @include transition(0.2s linear all);width:160px;
                &:after{
                    content:"";width:0;height:0;position:absolute;border-bottom:13px solid #FFF;border-left:10px solid transparent;
                    border-right:10px solid transparent;left:0;right: 0;margin:auto;top:-12px;
                }
                a{display: block;float:none;color:#000;font-size: 13px;font-weight: 400;color:#666;height:50px;text-align:center;padding:0;line-height:50px;
                  border-bottom:1px solid #eaeaea;
                  &:hover{color:#009edb;}
                }
            }
        }
        .src {
            float: left;
            .ico_src {
                width: 26px; height: 26px; display: inline-block; vertical-align: middle; margin-top: 27px;
                background: url('../images/material/ico_src.png') no-repeat center center; cursor: pointer;
            }
        }
        .burger_mn {
            width: 80px; height: 80px; position: relative; float: left; background: $c_ylow; margin-left: 50px; cursor: pointer;
            @include transition(background ease-out 0.3s);
            a {
                position: absolute; left: 0; right: 0; bottom: 0; top: 0; margin: auto; height: 25px; width: 32px;
                span {
                    height: 4px; background: #fff; display: inline-block; width: 100%; position: absolute; left: 0; right: 0;
                    @include transition(width ease-out 0.3s);
                    &:nth-child(1) {top:0; bottom: auto; left: auto}
                    &:nth-child(2) {top: 7px; bottom: auto; left: auto}
                    &:nth-child(3) {bottom: 7px; top: auto; width: 50%; right: auto;}
                    &:nth-child(4) { bottom: 0; top: auto; width: 50%; right: auto;}
                }

            }
            &:hover {
                background: #e79b07; @include transition(background ease-out 0.3s);
                a {
                    span {
                        @include transition(width ease-out 0.3s);
                        &:nth-child(1),&:nth-child(2) {width: 50%;}
                        &:nth-child(3),&:nth-child(4) {width: 100%;}
                    }
                }    
            }
            /*a {
                width: 30px; height: 24px; background: url('../images/material/burger_ico.png') no-repeat right center; position: absolute; left: 0; right: 0; top: 0;
                bottom: 0; margin: auto;  display: block; @include transition(background-position ease-out 0.3s);
            }
            &:hover {
                a {
                    background-position: left center;@include transition(background-position ease-out 0.3s);
                }
            }*/
        }
    }
    &.float { 
        padding-top: 0 !important; position: fixed;@include boxShadow(0 8px 35px -15px rgba(0,0,0,0.4));
        .wrapper {width: auto;}
        .logo {
            margin-left: 175px; z-index: 2; margin-top: 16px;
            img {
                display: none;
                &.stay {display: block;}
            }
        }
        .rh_head {
            z-index: 2;
            .main_nav {
                float: left; @extend .after_clear; margin-top: 18px; margin-right: 20px;
                ul {
                    li {
                        a {color: #333; }
                    }
                }
            }
            .src {
                .ico_src {background: url("../images/material/ico_src_2.png") no-repeat center center; @include transition(background 0.25s ease-out);}
            }
        }
    }
    .white_drop {
        position: absolute; left: 0; right: 0; top: 0; background: #fff; height: 0; content: '';
    }
}
.side_mnu {
    display: block; position: fixed; z-index: 10; left: 0; right: 0; top: 0; bottom: 0; display: none;
    .overlay_side {
        position: fixed; left: 0;right: 0;bottom: 0;top: 0; margin: auto;
        background: rgba(0, 0, 0, 0.6); z-index: 2;
    }
    .wrap_side {
        position: fixed; top: 0; bottom: 0; right: 0; background: #fff; z-index: 5;width: 400px;@include boxSizing(border-box); 
        padding: 150px 75px 0; 
        .bg-nav{position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: url(../images/material/bg-menu-box.jpg) no-repeat top left; z-index: -1;}
        &:after{
            content:"";width:100%;height:100%;;position:absolute;bottom:196px;right:0;background: url(../images/material/bg-menu-1.jpg) no-repeat bottom right;
        }
        &:before{ 
            content:"";left:60px;top:180px;position:absolute;bottom:197px;right:70px;background: url(../images/material/bg-menu-2.png) no-repeat top right;
            background-size: 100% 100%;
        }
        .nav_side {
            position: relative; z-index: 2;
            ul {
                li {
                    a {
                        font-size: 20px; font-weight: 700; text-transform: capitalize; color: $c_333; display: block; 
                        padding: 10px 0; margin-bottom: 15px;position: relative;
                    }
                    ul {
                        padding-left: 20px; display: none;
                        li {
                            a {font-size: 20px; font-weight: 400; margin-bottom: 12px; padding: 0;}
                        }
                    }
                    &.hv_child {
                        > a {
                            &:after {
                                display: inline-block; width: 7px; height: 10px; background: url('../images/material/arr_menu_side.png') no-repeat;
                                content: ''; vertical-align: middle; margin-left: 20px; @include transition(transform 0.25s ease-out);
                            }
                            &.drop {
                                &:after {
                                    @include transform(rotate(90deg)) @include transition(transform 0.25s ease-out);
                                }
                            }
                        }
                    }
                }
                .in_mobile {display: none;}
            }
        }
        .btm_side {
            position: absolute; bottom: 0; left: 0; right: 0; padding: 35px 75px 65px; background: #f7f7f7; display: block;z-index:2;
            .btm_src {
                position: relative;
                form {
                    input[type="text"] {
                        font-size: 20px; color: $c_666;font-style: italic;  background: transparent; border: none; border-bottom: 1px solid $c_999;
                        padding: 15px 0; width: 100%; @include boxSizing(border-box);
                    }
                    input[type="submit"] {
                        width: 50px; height: 26px; border: none; background: url('../images/material/ico_src_2.png') no-repeat center; padding: 0;
                        position: absolute; right: 5px; top: 0; bottom: 0; margin: auto; cursor: pointer;
                    }
                    @include placeholder {color: $c_666; font-style: italic;}
                }
            }
            .lang {
                font-size: 20px; color: $c_666; margin-top: 20px;
                a {
                    font-size: 20px; color: $c_666; text-transform: uppercase; display: inline-block;vertical-align: middle;
                    @include transition(color ease-out 0.25s);
                    &:hover,
                        &.active {
                        color: #1f73ad;@include transition(color ease-out 0.25s);
                    }
                    &.active {font-weight: 700;}
                }
                span {display: inline-block; margin: 0 5px; vertical-align: top;}
            }
        }
    }
    .close {
        width: 80px; height: 80px; position: absolute; right: 0; top: 0; background: $c_ylow url('../images/material/close_side.png') no-repeat center;
        cursor: pointer;z-index:2;
    }
    .mCustomScrollBox {padding-right: 30px;}
    .mCSB_inside > .mCSB_container {margin-right: 0;}
    .mCSB_scrollTools {opacity: 1;}
    .mCSB_scrollTools .mCSB_dragger {margin-right: -30px;}
    .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background: #1f73ad;
    }
    .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
        width: 2px;
    }
}


/* homepage
----------------------------------------------------------------------------------------------*/
.slideHome {
    position: relative; 
    .slider { 
        .slide {
            position: relative;
            .images {
                img {display: block; height: 100vh; overflow: hidden; width: 100%;}
            }
            .caption {
                left: 0; right: 0; position: absolute; top: 52%; @include transform(translateY(-52%));
                .txt {width: 710px;}
                h1 {
                    color: #fff; font-size: 50px;  font-weight: 300; line-height: 60px; margin-bottom: 25px;
                    b,strong {
                        display: block; font-weight: 800;
                    }
                }
                p {color: #fff; font-size: 20px; line-height: 32px; margin-bottom: 30px;}
                .link {text-align: left;}
            }
        }
        .slick-dots {
            position: absolute; left: 0; right: 0; text-align: center; bottom: 75px;
            li {
                display: inline-block; margin: 0 5px;
                button {
                    width: 17px; height: 17px; @include boxSizing(border-box); background: no-repeat; font-size: 0; border: none;
                    background: #fff; cursor: pointer; border: 4px solid #fff; padding: 0;@include transition(background 0.25s ease-out);
                }
                &.slick-active,
                &:hover {
                    button {
                        background: transparent; @include transition(background 0.25s ease-out);
                    }
                }
            }
        }
    }
}

.top_sec {
    text-align: center;
    h2 {font-size: 39px; font-weight: 700; line-height: 50px; margin-bottom: 20px; color: $c_333;}
    h6 {font-size: 25px; font-weight: 300; color: $c_333;}
    p {font-size: 20px; line-height: 30px; color: $c_333;}
    &.white {
        h2 {color: #fff;}
        p {color: #fff;}
    }
}
.home_business {
    padding: 150px 0 75px; background: url('../images/content/bg_service.jpg') no-repeat; @include boxSizing(border-box); max-height: 100vh; overflow: hidden;
    .top_sec {
        h2 {color: #333;}
        h6 {color: #555;}
    }
    .listing {
        margin: 80px -70px 0;
        .item {
            padding: 85px 55px 80px; @include boxSizing(border-box); cursor: pointer; @include transition(background ease-out 0.3s);
            position: relative;width:31%;margin:0 1.75% 0;height:494px;

            h2 {
                font-size: 39px;  font-weight: 700;  color: #fff; margin-bottom: 50px; @extend .after_clear; height: 78px;
                position: relative; z-index: 2;
                span {width: 55px; display: inline-block; height: 50px; vertical-align: middle; margin-right: 30px;}
                label {display: inline-block; vertical-align: middle; width: 75%;}

            }
            p {font-size: 20px; color: #fff; line-height: 30px; opacity: 0.6; margin-bottom: 45px;position: relative; z-index: 2;}
            a {position: relative; z-index: 2;}
            .text-hover{opacity: 0;position: relative;z-index: 3;visibility: hidden;@include transform(scale(.95));@include transition(all ease-out 0.3s);}
            .cover{
                position: absolute;top:0;bottom:0;right:0;left:0;margin: auto;width:100%;height:100%;overflow:hidden;z-index:0;
                img{position: relative;max-width:none;height: 100%;}   
            }
            h6{font-size:31px;font-weight:bold;color:#FFF;position:absolute;bottom:30px;right:30px;@include transition(all ease-out 0.3s);}


            &:hover { 
                p {opacity: 1;}
                &:before {
                    opacity:1;
                }
                .text-hover{opacity: 1;visibility: visible;@include transform(scale(1))}
                h6{opacity: 0}
            }

            &:before {
                content: ''; display: block; position: absolute;background-size: 100%; @include transition(all ease-out 0.3s);z-index: 2;
                left: 0; right: 0; top: 0; bottom: 0; margin: auto; opacity: 0;@include boxShadow(0px 5px 50px -5px rgba(51,51,51,0.7));                
                background:rgba(38,62,104,0.90);
            }
            &:nth-of-type(2) {
                &:before {
                    background:rgba(17,64,96,0.90);
                }
            }
            &:nth-of-type(3) {
                &:before {
                    background:rgba(0,86,128,0.90);
                }
            }
            &:nth-child(3n){margin-right: 0;}
        }
        .slick-arrow {
            position: absolute; top: 0; bottom: 0; width: 16px; height: 27px; border: none; font-size: 0;
            background: url('../images/material/arr_slider.png') no-repeat; margin: auto; cursor: pointer;
            &.slick-prev {left: -40px; @include transform(rotate(180deg));} 
            &.slick-next {right: -40px;}
        }
        &:after {display: block; clear: both; content: '';}
    }
}
.home_about {
    position: relative;min-height: 670px;@include boxSizing(border-box); overflow: hidden; height: 100vh;
    background: url('../images/material/bg_why_top.png') no-repeat left top;
    .text_about {
        position: absolute; width: 700px; top: 50%; @include transform(translateY(-50%));
        h2 {font-size: 39px; font-weight: 700; line-height: 50px; margin-bottom: 20px; color: $c_333;}
        h6 {font-size: 25px; font-weight: 300; color: $c_333; margin-bottom: 30px;}
        p {font-size: 20px; line-height: 30px; color: $c_999;}
    }
    .img_about {float: right;}
}

.home_wcu {
    padding: 135px 0 100px; position: relative;/* min-height: 670px;*/@include boxSizing(border-box); height: 100vh;overflow: hidden;
    background: url('../images/material/bg_why_top.png') no-repeat left top, url('../images/material/bg_why_btm.png') no-repeat right bottom;
    background-color:#dedede;
    &:before {
        content:''; display: block; position: absolute; left: 0; right: 0;  width: 100%; height: 52px; top: 0;
        background: url('../images/material/shadow_why.png') no-repeat center;
    }
    .top_sec {
        width: 800px; margin: 0 auto;
    }
    .list_wcu {
        margin-top: 85px;
        .col {
            display: inline-block; width: 23.5%; margin-right: 1.67%; @extend .after_clear; margin-bottom: 40px; vertical-align: top;
            &:nth-of-type(4n) {margin-right: 0;}
            .img {
                float: left; width: 100px; display: block;
                img { @include transition(transform ease-out 0.25s); display: block;}
            }
            .text {
                display: block; margin-left: 130px;
                p {font-size: 20px; color: $c_666; line-height: 30px; margin-bottom: 0;} 
            }
            &:hover {
                .img {
                    img {@include transform(scale(0.9)); @include transition(transform ease-out 0.25s);}
                }
            }
        }
    }
}
.home_contact {
    position: relative; height: 90vh; min-height: 670px; @include boxSizing(border-box); max-height: 100vh; overflow: hidden;
    background: url('../images/material/bg_contact.jpg') no-repeat left top; 
    background-size: cover;
    .text_contact {
        position: absolute; width: 770px; top: 50%; @include transform(translateY(-50%)); text-align: left;
        .title {
            font-size: 16px; text-transform: uppercase; color: $c_666; padding: 24px 0 0 30px; line-height: 14px; font-weight: 700;
            background: url('../images/material/bg_title_2.png') no-repeat left center; display: inline-block; margin-bottom: 40px;
            &.white {
                color: #fff;background: url('../images/material/bg_title.png') no-repeat left center;
            }
        }
        h1 {font-size: 49px; font-weight: 300; line-height: 59px; margin-bottom: 20px; color: #fff;}
        h6 {font-size: 25px; font-weight: 300; color: #fff; margin-bottom: 30px;}
    }
}

.link {
    text-align: center;
}
.listing {
    .item {
        float: left; width: 33.33%;
    }
    &.about_sub {
        padding: 0 100px;
        .item {
            width: 49%; margin-right: 2%; margin-bottom: 40px; @include boxSizing(border-box);border: 1px solid $c_ea;
            cursor: pointer; padding-bottom: 165px; position: relative;
            .img {
                position: relative; z-index: 1; display: block;
                img {display: block;}
            }
            .dec {
                padding: 35px 45px;@include transition(all ease-out 0.3s); position: absolute; z-index: 2;width:100%;
                background: #fff; bottom: 0;@include boxSizing(border-box); 
                h5 {font-size: 20px; font-weight: 700; color: $c_333; margin-bottom: 10px;}
                p {font-size: 16px; line-height: 24px; color: $c_666; margin-bottom: 0;}
                .btn {margin-top: 15px; height: 0; overflow: hidden; @include transition(all ease-out 0.3s); opacity: 0;}
            }
            &:nth-of-type(2n) {
                margin-right: 0;
            }
            &:hover {
                .dec {
                    margin-top: -40px; @include transition(all ease-out 0.3s); @include boxShadow(0 10px 35px -2px rgba(51,51,51,0.5));
                    .btn {height: 40px;@include transition(all ease-out 0.3s); opacity: 1;}
                }
            }
        }
    }
}

/* middle
----------------------------------------------------------------------------------------------*/
.middle {
    padding-bottom: 250px; background: url('../images/material/bg_middle.png') no-repeat bottom center;
    &.no_bg {
        background: #fff; padding-bottom: 0;
    }
}
.pg_title {
    position: relative;
    &.bg-grey{background: url(../images/banner/bg-banner-1.jpg) no-repeat center top ;background-size: cover;}
    .map{
        height:750px;position:relative;overflow:hidden;width:1500px;margin:auto;max-height:750px;
        img{position: absolute;top:0;bottom:-120px;left: 0;right:0;margin:auto;width:1500px;} 
        .pointer{
            width:140px;height: 50px;font-size:16px;line-height: 50px;color:#FFF;text-align: center;font-weight: bold;
            position: absolute;cursor:pointer;@include transition(0.1s background ease-out);
            .box{ width:140px;height: 50px;border:2px solid #FFF;margin:-2px 0 0 -2px;}
            span.line{
                content:"";width:2px;height:170px;position: absolute;left:0;right:0;margin:auto;top:100%;background: #FFF;
            }
            span.circle{
                content:"";display: block;width: 24px;height:24px;background: url(../images/material/bullet-ico.png) no-repeat ;
                position:absolute;left:0;right:0;margin:auto;bottom:-194px;
            }

            &.p-1{left:680px;top:240px;}
            &.p-2{left:960px;top:160px;}
            &.p-3{left:1120px;top:235px;}
            &.p-4{
                left:1270px;top:315px; width: 200px;
                .box{width:200px; margin:-2px 0 0 -2px;}
            }
            &.p-5{left:790px;top:165px;}
            &:hover{
                background: rgba(4,82,138,0.5);
            }
        }
        .sub-loc{
            position:absolute;left:0;bottom:40px;display:none;
            .scroll{max-height:325px}
            .box{
                width:180px;height: 50px;border:2px solid #FFF;margin:0px 0 13px 0px;font-size:16px;line-height: 50px;color:#FFF;text-align: center;
                font-weight: bold;@include transition(0.1s background ease-out);cursor:pointer;position:relative;margin-left:43px;background:#acacac;
                &:hover{
                    background: rgba(4,82,138,0.5);
                }
                &.active{background:#2a76ac;
                         span.line{width:20px;}
                         span.circle{opacity: 1;}
                }
            }
            span.line{
                content:"";width:0px;height:2px;position: absolute;left:-20px;margin:auto;top:0;bottom:0;background: #FFF;@include transition(0.3s all ease-out);
            }
            span.circle{
                content:"";display: block;width: 24px;height:24px;background: url(../images/material/bullet-ico.png) no-repeat ;opacity:0;
                position:absolute;left:-43px;margin:auto;bottom:0;top:0;@include transition(0.3s all ease-out);
            }

            .data-loc{display: none;}
            a.return{margin-top: 50px;display: inline-block;font-size:16px;color:$c_ylow;text-transform:uppercase;font-weight:bold;padding:0 0 0 30px;
                     position:relative;
                     &:after{
                         content:"";position:absolute;left:0;top:3px;border-right:5px solid $c_ylow;border-top:5px solid transparent;border-bottom:5px solid transparent;
                     }
            }
        }

        .detail-loc-info{
            position:absolute;bottom:80px;right:38px;width:385px;display:none;
            h5{font-size: 25px;color:#FFF;font-weight:bold;margin:0 0 20px 0;text-shadow:0px 0px 5px #999;}
            .bg-white{
                background: #FFF;@include boxShadow(0 0 55px -20px #000);padding:50px 35px;position:relative;
            }
            .close{
                position: absolute;width:50px;height:50px; background:$c_ylow url(../images/material/close-ico-2.png) no-repeat center;top:-20px;right:-38px;
                text-indent:-99999px;                
                &:hover{background-color:#426ab3 }
            }
            h6{font-size:20px;color:#426ab3;font-weight:bold;margin:0 0 15px 0;}
            p,a{
                font-size:16px;color:#333;line-height: 24px;
                span{padding-right:30px;}
            }
            p{margin-bottom: 30px}
        }

    }
    .img_bg {
        img {display: block; height: auto;}
    }
    .txt_title_pg {
        width: 1230px; position: absolute; left: 0; right: 0; margin: auto; top: 45%; @include transform(translateY(-15%));
        text-align: center;
        h2 {font-size: 49px; font-weight: 300; color: #fff; line-height: 59px; margin-bottom: 25px;}
        h3 {font-size: 76px; font-weight: 900;  color: #fff; margin-bottom: 35px;}
        p {font-size: 20px; line-height: 30px; color: #fff;font-weight:300;}
        &.no_img {
            top: 50%; @include transform(translateY(-50%));
        }
    }
    &.thank-banner{
        height:87vh;background:url(../images/banner/banner-thanks.jpg) no-repeat center;background-size:cover; min-height: 650px;
        .txt_title_pg {
            top:38%;
            h2{font-size:76px;font-weight:800;margin-bottom: 40px;line-height:76px;}
            p{margin:0 0 40px 0;}
            a {margin-left: -20px;}
        }   
    }
}
.banner_pg {
    position: relative;
    .images {
        margin-top: -25%;position: relative;
        img {display: block;}
    }
    .block_addrss {
        width: 400px;background: #fff; display: block; position: absolute; top: 50%; left: 100px;@include boxShadow(0px 0px 150px -50px);
        padding: 70px 45px; @include transform(translateY(-50%)); @include boxSizing(border-box);
        h5 {font-size: 20px; font-weight: 700; color: #2483c6; margin-bottom: 30px;}
        p {
            font-size: 16px; line-height: 24px; margin-bottom: 25px;
            &.phone {
                margin-bottom: 0; line-height: 30px;
            }
        }
    }
    &.no-margin{
        .images{margin-top: 110px;}
    }
}
.inner_text {
    padding: 80px 100px 45px;@extend .after_clear; border-bottom: 1px solid $c_ea; margin-bottom: 80px;position:relative;

    > h2 {font-size: 49px; font-weight: 300; margin-bottom: 75px;}
    .wrap-stay{position: relative;
               &:after{@extend .after_clear;}

    }
    .col-2{
        .col{width:49%;margin:0 2% 20px 0;float:left;
             &:nth-child(2n){margin-right: 0;}
        }
        &:after{@extend .after_clear;}
    }
    ul,ol {
        margin-bottom: 25px;
        &.bold{font-weight:700;}
        li {
            padding-left: 35px; font-size: 20px; line-height: 30px; color: $c_666; margin-bottom: 5px; position: relative;
            &:before {
                position:absolute; left: 3px; top: 12px; width: 8px; height: 8px; background: $c_ylow; display: inline-block;
                content: '';
            }
        }
    }
    ol{
        margin:0 0 25px 25px;
        li{padding:0;list-style:decimal;
           &:before{display: none;}
        }
    }

    .lf_txt {
        float: left; width: 400px; display: block;position: absolute;top:0;
        h3 {font-size: 25px; font-weight: 700; line-height: 35px; color: $c_333;}
        .sub_carrer {
            ul {
                margin-right: 50px;
                li {
                    text-align: right; margin-bottom: 20px;
                    a {
                        font-size: 25px; text-transform: uppercase; color: $c_666;
                        &:hover,
                            &.active {
                            color: #2484c6;
                        }
                        &.active {font-weight: 700;}
                    }
                }
            }
        }
    }
    .rh_txt {
        float: right; width: 800px; display: block;
        p {font-size: 20px; line-height: 34px; color: $c_333; font-weight: 300;
           &.with-ico{
               padding:0 0 0 102px;position: relative;margin-bottom:40px;
               img{position: absolute;left: 0;top:10px;}
           }
        }
        h6 {font-size: 20px; font-weight: 700; color: $c_666; margin-bottom: 10px;line-height: 28px;}
    }
    &.contact {
        border-bottom: none; margin-bottom: 0;
        .lf_txt {
            h3 {padding-right: 70px;}
        }
    }
    &:last-of-type {
        padding-top: 0; border-bottom: none; margin-bottom: 0;
    }
    &:first-of-type {
        padding-top: 80px;
    }    
    &.our-client{
        h4{font-size:31px;font-weight:bold;color:#333;padding-bottom: 20px;border-bottom: 1px solid #CCC;}
    }
    .client-list{
        margin:0 0 80px 0 ;
        a{float:left;margin:0 172px 0 0; 
          &:nth-child(3n){margin-right: 0;}
          img{ filter: grayscale(100%); }
          &:hover{
              img{ filter: grayscale(0); @include transition(all ease-out 0.25s); }
          }
        }
        &:last-child{margin-bottom: 0;}
        &:after{@extend .after_clear;}
    }
}
.next-read{
    background: url('../images/material/bg_client.jpg') ;height:auto;color:#FFF;padding:80px 0px;margin:80px 0 0 0;
    .wrapper{width:1170px;}
    .left{
        width: 290px;
        h6{font-size:16px;font-weight:bold;color:#FFF;margin:0 0 20px 0;}
        h2{font-size: 49px;font-weight:300;margin:0 0 0 -3px}
    }
    .right{
        width:800px;
        p{font-size:20px;line-height: 34px;margin:0 0 0px 0;}
    }
}

.content_service {
    padding: 135px 0 100px; background: url('../images/material/bg-mid-industrial.jpg') no-repeat center;
    background-size: cover;
    .top_sec {width: 830px; margin: 0 auto;}
}
.service_list {
    margin-top: 40px;
    .item {
        width: 48.75%; margin-right: 2.5%; padding: 30px; @include boxSizing(border-box);
        @extend .after_clear; position: relative; @include transition(box-shadow ease-out 0.25s);
        &:nth-of-type(2n) {margin-right: 0;}
        .ico {width: 70px; float: left; position: relative; z-index: 2;}
        .txt_item {
            display: block; margin-left: 115px; z-index: 2; position: relative;
            h6 {color: #fff; font-size: 20px;  font-weight: 700; margin-bottom: 5px;}
            p {color: #fff; font-size: 16px; line-height: 24px; margin-bottom: 0; opacity: 0.8}
        }
        &:before {
            content: ''; left: 0; right: 0; bottom: 0; top: 0; margin: auto; background: #009edb; opacity:0;
            display: block; height: 100%; position: absolute; z-index: 1;@include transition(all ease-out 0.25s);
            @include boxShadow(0 0 40px 0px rgba(51,51,51,0.5)); @include transform(scale(0.9));
        }
        &:hover {
            &:before {
                opacity: 1; @include transition(all ease-out 0.25s);@include transform(scale(1));
            }
            .txt_item {
                p {opacity: 1;} 
            }
        }
    }
}
.address-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -20px;
    width: calc(100% + 20px);
    .list{
        flex: 1 0 25%;
        max-width: 25%;
        padding: 0 20px;
        box-sizing: border-box;
        margin-bottom: 20px;
        h4{
            font-weight: 700;
            margin-bottom: 10px;
        }
        p{
            font-size: 16px;
            line-height: 1.5;
        }
    }
}
.content_market {
    padding: 135px 0 115px; background: url("../images/material/bg_why_top.png") no-repeat left top, url("../images/material/bg_why_btm.png") no-repeat right bottom;

}
.slider_market {
    padding: 0 70px; position: relative; margin-top: 80px;
    .slick-list {padding-bottom: 40px;}
    .slide {
        width: 300px; margin-right: 30px; @include boxSizing(border-box); border: 1px solid $c_ea;
        padding-bottom: 90px; position: relative;
        > a {display: block; position: relative;}
        .img {
            img {display: block;}
        }
        .dec {
            padding: 30px 25px 25px;position: absolute; left: 0; right: 0; bottom: 0; background: #fff;
            @include transition(all 0.3s ease-out);
            h6 {
                font-size: 20px; color: $c_333; font-weight: 700; display: table; margin-bottom: 0px;
                line-height: 24px; height: 50px; display: table;
                span {display: table-cell; vertical-align: middle;}
            }
            p {font-size: 16px; line-height: 22px; color: $c_333; margin-bottom: 10px; margin-top: 15px;}
            .btn {margin-top: 10px;}
            .hov {height: 0;position: relative; overflow: hidden;@include transition(height ease-out 0.3s);}
        }
        &:hover {
            .dec {
                @include boxShadow(0 5px 30px -2px rgba(51,51,51,0.5)); @include transition(all 0.3s ease-out);
                .hov {
                    height: 185px;
                }
            }
        }
    }
    .slick-arrow {
        position: absolute; top: 32%; bottom: auto; width: 16px; height: 27px; border: none; font-size: 0;
        background: url('../images/material/arr_slider.png') no-repeat; margin: auto; cursor: pointer;
        &.slick-prev {left: 0; @include transform(rotate(180deg));}
        &.slick-next {right: 0;}
    }
}
.content_galery {
    background: url("../images/material/bg_middle.png") no-repeat bottom center; padding: 135px 0 115px; position: relative;
    background-size: 100%; 
    &:before {
        content: '';display: block; position: absolute;left: 0;right: 0;width: 100%;height: 52px;
        top: 0;background: url(../images/material/shadow_why.png) no-repeat center;
    }
    .slider_galery {
        width: 1170px; margin: 45px auto 0; position: relative; padding-top: 45px; 
        background: url('../images/material/shadow_slide.png') no-repeat top center; background-size: 95%;
    }
    .slide {
        position: relative;
        .text_slide {
            position: absolute; left: 70px; right: 70px; bottom: 45px;@extend .after_clear;
            h6 {font-size: 31px; color: #fff; font-weight: 700; float: left;}
            .btn {float: right; margin-top: 0;}
        }
    }
    .slick-arrow {
        position: absolute; top: 0; bottom: 0; width: 16px; height: 27px; border: none; font-size: 0;
        background: url('../images/material/arr_slider.png') no-repeat; margin: auto; cursor: pointer;
        &.slick-prev {left: -70px; @include transform(rotate(180deg));} 
        &.slick-next {right: -70px;}
    }
}
.content_client {
    background: url('../images/material/bg_client.jpg') no-repeat top center; padding: 135px 0 135px 0; 
    background-size: cover; height: auto !important; 
    .list_client {
        text-align: center; margin: 60px 0 40px;
        .item {
            display: inline-block; padding: 19px 30px; border-right: 1px solid #fff;
            &:last-child {
                border-right: none;
            }
        }
    }
}
.list_career {
    .karir {
        position: relative; padding: 40px; border: 1px solid $c_ea; @include boxSizing(border-box); display: block;
        border-bottom: none; cursor: pointer; @include transition(box-shadow ease-out 0.25s);
        .dt {font-size: 13px; font-weight: 700; margin-bottom: 10px;  display: block;}
        h5 {font-size: 25px; font-weight: 300; color: $c_333; margin: 0;}
        .btn {position: absolute; top: 0; right: 40px; bottom: 0; margin: auto; height: 40px;}
        &:last-of-type {
            border-bottom: 1px solid $c_ea;
        }
        &:hover {
            @include transition(box-shadow ease-out 0.25s); @include boxShadow(0 0 30px -6px #aaa);
        }
    }
}
.pagging {
    text-align: right; margin-top: 45px;
    a {
        font-size: 16px; color: $c_666; display: inline-block; text-transform: uppercase;
        &.prev { margin-right: 40px;}
        &.next {margin-left: 40px;}
        &.next,
        &.prev {
            &:hover {
                color:#2484c6;
            }
        }
        &.active {
            color: #2484c6; font-weight: 700;
        }
    }
    span {display: inline-block; margin: 0 5px;}
}
#map_contact {
    height: 615px;
}
.form_contact {
    .row {
        margin-bottom: 20px; @extend .after_clear;
        .field {
            position: relative;
            input[type="text"],
            input[type="number"],
            input[type="email"],
            select, textarea {
                font-size: 20px; color: #2484c6; font-weight: 200; border: none; border-bottom: 1px solid $c_ea;font-family: "Open Sans";
                width: 100%; @include boxSizing(border-box); padding: 10px 25px 20px; @include transition(border-color linear 0.3s);
                + span {
                    position: absolute; left: 25px;  font-size: 20px; font-weight: 300; color: $c_333;
                    @include transition(font-size linear 0.2s); top: 12px;
                }
            }
            textarea {
                resize: none; height: 100px;
            }

            &.focus {
                input[type="text"],
                input[type="number"],
                input[type="email"],
                select, textarea {
                    @include transition(border-color linear 0.3s); border-color: #2484c6;
                    + span {
                        font-size: 13px; text-transform: uppercase;@include transition(font-size linear 0.2s); color: $c_999;
                        font-weight: 400;
                    }
                }
            }
            .input-file{
                font-size:18px;height:54px;line-height: 20px;white-space: nowrap;border-bottom: 1px solid $c_ea;display: block;width:100%;
                padding:10px 25px 20px ;@include boxSizing(border-box);font-weight:300;cursor:pointer;
                background:url(../images/material/file-ico.png) no-repeat right 3px;
                &.filled{border-color: #2484c6;     color: #2484c6;font-weight:300;}
                input[type="file"] {
                    right: 0; width: 100%; @include boxSizing(border-box); cursor: pointer;
                    + span {display: block; white-space: nowrap;text-overflow: ellipsis; padding-right: 4%;}
                }
            }
        }
        button.btn {border: none; cursor: pointer; margin-top: 20px;}
        .col {
            float: left;
            &:nth-of-type(2) {float: right;}
        }
        &:last-of-type {
            margin-top: 40px;
            .col {
                &:last-of-type {padding-right: 45px;}
            }
        }
    }
}
.wrap-popup{
    position: fixed;overflow: auto;width:100%;height:100%;left:0;top:0;background:rgba(0,0,0,.9);z-index:9999;display:none;
    .overlay{position: absolute;top:0;bottom:0;left:0;right: 0;}
    .box-popup{
        position: relative;width:1170px;margin:5% auto;color:#FFF;
        .close{
            position: absolute;width:60px;height:60px;background: #ffb72b;z-index:2;top:-15px;right:-30px;
            &:after{content:"";position: absolute;width:21px;height:20px;top: 0;bottom:0;left:0;right:0;margin:auto;
                    background: url(../images/material/close-ico.png) no-repeat;@include transition(0.3s ease-out all);
            }
            &:hover{
                &:after{@include transform(rotate(180deg));}
            }
        }
        .slider-1{
            .slick-arrow{
                width:16px;height:27px;padding:0;border:none;@include boxSizing(border-box);background:url(../images/material/arr-ico.png) no-repeat ;
                top:-300px;bottom:0;margin:auto;text-indent:-9999px;cursor:pointer;position:absolute;@include transition(0.2s ease-out all);
                &.slick-next{
                    background-position: right;right:-100px;
                    &:hover{right:-105px;}         
                }
                &.slick-prev{
                    background-position: left;left:-100px;
                    &:hover{left:-105px;}         

                }
            }
            .img{
                margin:0 0 190px 0;
                img{display: block;}
            }
            .text{
                h6{font-size:25px;font-weight:bold;margin:0 0 20px 0;}
                p{font-size:20px;font-weight:300;line-height: 40px;}
            }
        }
        .slider-2{
            position:absolute;left:-100px;right:-100px;top:530px;
            .slide{
                padding:0 15px;cursor:pointer;
                &.slick-current{
                    .box:after{opacity: 0;}
                    .box:before{opacity: 1;}

                }   
            }
            .box{
                position:relative;
                &:after{
                    content:"";@include boxSizing(border-box);position:absolute;top:0;bottom:0;left:0;right:0;background:rgba(0,0,0,0.5);opacity: 1;
                    @include transition(0.2s ease-out all);
                }
                &:before{
                    content:"";@include boxSizing(border-box);border:2px solid #FFF;position:absolute;top:0;bottom:0;left:0;right:0;opacity: 0;
                    @include transition(0.2s ease-out all);
                }
            }
        }
    }
}

.scrollify {
    @include boxSizing(border-box); height: 100vh; overflow: hidden;
    &.novh{ height: 80vh; }
}   



.scroll-wrap{@extend .after_clear;position:relative;height:532px;}
/* footer
----------------------------------------------------------------------------------------------*/
footer {
    text-align: center; padding: 20px 0; position: relative; 
    height: auto !important; 
    background: url('../images/material/bg_left_foot.png') no-repeat left center, url('../images/material/bg_right_foot.png') no-repeat right center;
    .logo_foot {float: left; margin-top: 15px;}
    .right_logo {float: right;}
    .copyright_txt {
        display: inline-block; margin-top: 18px;
        p {
            font-size: 13px; text-transform: uppercase; color: $c_333; line-height: 20px; margin: 0;
            span {display: block;}
        }
    }
}